.kanabanWrapper {
    height: 100%;
    // overflow: auto;

    height: 100%;
    overflow: auto;
    /* overflow: hidden; */
    max-height: calc(100vh - 130px);
    box-sizing: border-box;
    padding: 2px 0.5rem 0 0.5rem;
    scrollbar-width: stable;
}

.kanaban {
    display: grid;
    grid-gap: 10px;
    grid-auto-columns: 270px;
    grid-auto-flow: column;
    max-height: 100%;

    .kanabanColumnSkeleton {
        display: flex;
        flex-direction: column;
        gap: 10px;
        // box-shadow: 0px 0px 8px 2px #D3D3D380;
        // background: rgba(255, 255, 255, 0.8);
        // background: linear-gradient(90deg,
        //         rgba(240, 240, 240, 0.8) 25%,
        //         rgba(220, 220, 220, 0.8) 50%,
        //         rgba(240, 240, 240, 0.8) 75%);
        // background-size: 200% 100%;
        // animation: shimmer 1.5s infinite linear;

        .kanabanColumnHeader {
            justify-content: space-between;
            background: #f0f0f0;
            // height: 40px;
            animation: shimmer 1.5s infinite linear;

            position: sticky;
            top: 0px;
            padding: 16px;
            border-left: 5px solid #4178D1;
            font-size: 14px;
            font-weight: 500;
            background-color: white;
            border-radius: 4px 8px 8px 4px;
            box-shadow: 0px 0px 8px 2px #D3D3D380;
            display: flex;
            align-items: center;
            z-index: 2;

            .kanabanColumnTitle,
            .kanabanColumnCount {
                width: 20%;
                height: 14px;
                background: #e0e0e0;
                border-radius: 4px;
                margin: 4px;
                animation: shimmer 1.5s infinite linear;
            }

            .kanabanColumnTitle {
                width: 50%;
            }
        }

    }

    .KanbanCard {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: 12px;
        border-radius: 6px;
        background: #f8f8f8;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        min-height: 60px;
        animation: shimmer 1.5s infinite linear;

        &Header {
            height: 14px;
            width: 30%;
            background: #e0e0e0;
            border-radius: 4px;
            animation: shimmer 1.5s infinite linear;

        }
        &Body {
            height: 40px;
            width: 80%;
            background: #e0e0e0;
            border-radius: 4px;
            animation: shimmer 1.5s infinite linear;

        }

        &Footer {
            height: 14px;
            width: 40%;
            background: #d0d0d0;
            border-radius: 4px;
            animation: shimmer 1.5s infinite linear;
        }
    }

    @keyframes shimmer {
        0% {
            background-position: -200% 0;
        }

        100% {
            background-position: 200% 0;
        }
    }
}