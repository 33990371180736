//@import "./_config.scss";
@import './_config.less';
@import './_statuses.less';

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

input:-internal-autofill-selected {
  background-color: white !important;
}

input:-webkit-autofill:focus {
  -webkit-background-clip: text;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#root {
  height: 100%;
}

hr {
  border: none;
  border-top: 1px @border-color-base solid;
  margin: 15px auto;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.outer-wrapper {
  display: grid;
  grid-template-columns: 225px auto;
  height: 100%;

  .page-body {
    display: grid;
    flex: 1;
  }
}

.loading-screen {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  flex-wrap: nowrap;
}

.page.login {
  padding: 0;
  margin: auto;
  text-align: center;
  display: flex;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
  // background-color: @primary-color;
  background: linear-gradient(to left, @primary-color 50%, #f8818e 50%);

  .ant-input-affix-wrapper {
    border-width: 0;
    border-bottom-width: 1px;

    &:not(.ant-input-affix-wrapper-disabled):hover {
      box-shadow: none;
    }
  }
}

.ant-collapse-header-text {
  &>.anticon {
    color: @primary-color;
  }

  h1 {
    margin-bottom: auto;
    line-height: 1.5rem;
  }
}

.ant-collapse-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.error {
  color: @error-color
}

.form {
  &-fields-wrapper {
    display: grid;
    justify-items: stretch;
    gap: 1rem;
    grid-template-columns: repeat(3, 1fr);

    &--descriptions {
      label {
        font-weight: 500;
      }
    }

    &--four {
      grid-template-columns: repeat(4, 1fr);
    }

    &--five {
      grid-template-columns: repeat(5, 1fr);
    }

    &--horizontal {
      display: flex;
      gap: 1rem;
      flex-direction: column;

      & .form-field {
        display: grid;
        gap: 1rem;
        grid-template-columns: 2fr 3fr;
      }
    }

    &--vertical {
      display: flex;
      gap: 1rem;
      flex-direction: column;

      & .form-field {
        display: flex;
        gap: 1rem;
        flex-direction: column;
      }
    }

    & .form-field {
      &:first-child {
        justify-self: start;
      }

      &:last-child {
        justify-self: end;
      }

      &:not(:last-child) {
        margin-bottom: 0;
      }
    }
  }

  &-field {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    justify-content: flex-start;
    width: 100%;

    label {
      font-weight: 500;
    }

    &--required {
      label {
        &:before {
          display: inline-block;
          margin-right: 4px;
          color: #ff4d4f;
          font-size: 14px;
          font-family: SimSun, sans-serif;
          line-height: 1;
          content: '*';
        }
      }
    }

    &--horizontal {
      flex-direction: row;
      gap: 1rem;
      justify-content: flex-start;
      align-items: center;
    }

    &--span2 {
      grid-column: span 2;
    }

    &--span3 {
      grid-column: span 3;
    }

    &--span4 {
      grid-column: span 4;
    }

    &:not(:last-child) {
      // margin-bottom: 20px;
    }
  }
}


.inputfield {
  &--percentage {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    align-items: center !important;

    &::after {
      content: "%";
      padding: 0 11px 0 0;
    }
  }
}

.box {
  border-radius: @collapse-panel-border-radius;
  padding: 15px;

  &--white {
    &:extend(.box);
    border: 1px solid @border-color-base;
  }

  &--light-gray {
    &:extend(.box);
    background: #F9F9F9C2;
  }

  &--blue-light {
    &:extend(.box);
    background: @blue-light;
  }

  &--modal {
    background: rgba(246, 246, 246, 0.69);
    box-shadow: 1px 1px 6px 0px #D9D9D9 inset;
  }
}

.actions {

  &:has(.button),
  &:has(button) {
    display: flex;
    gap: 0.5rem;
    justify-content: center;
  }

  &.align--left {
    justify-content: left;
  }

  &.align--right {
    justify-content: right;
  }
}

.toggle-token {

  &.ant-radio-group,
  &.ant-checkbox-group {
    label {
      margin: 0.5rem;

      &.ant-checkbox-wrapper,
      &.ant-radio-wrapper {
        border: 1px #f3f3f3 solid;
        border-radius: 5px;
        padding: 5px;
        background: #f3f3f3;

        &:has(input:checked) {
          background: #cac6c6;
        }

        .ant-checkbox-inner {
          display: none
        }
      }
    }
  }
}

.status-tag {
  border-radius: 5px;
  display: block;
  text-align: center;
  background-color: @color-lightgray-2;
  padding: 3px 5px;

  .status-styles(status-tag, length(@statuses));
  .project-status-styles(status-tag, length(@statuses));
}

.blue {
  color: @blue-color;
}

.ant-input[disabled]:not(.always-visibe) {
  color: #000;
  background-color: transparent !important;
  border-color: transparent !important;
  border: none !important;
  background: none !important;
  cursor: default;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input):not(.always-visible) .ant-select-selector {
  background-color: transparent !important;
  border-color: transparent !important;
  border: none !important;
  background: none !important;
}

.@{select-prefix-cls} {
  &-borderless {
    .@{select-prefix-cls}-selector {
      border: none !important;
      box-shadow: none !important;
    }
  }
}

.ant-input-affix-wrapper-disabled {
  background-color: transparent !important;
  border-color: transparent !important;
  border: none !important;
  background: none !important;
}

.ant-radio-button-wrapper-disabled {
  background-color: transparent !important;
  border-color: transparent !important;
  border: none !important;
}

.ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
  color: #333;
  font-weight: 700;
  display: block;
}

.ant-select-disabled .ant-select-arrow {
  cursor: not-allowed;
  display: none;
}

//  .toggle-token.ant-checkbox-group label.ant-checkbox-wrapper, 
//  .toggle-token.ant-radio-group label.ant-radio-wrappe, 
.ant-radio-wrapper-disabled {
  border: none !important;
  background: none !important;
  display: none;
}

.ant-form-item-label>label {
  font-weight: 500;
}

.ant-radio-button-wrapper-disabled {
  display: none;
}

// .ant-radio-button-wrapper-checked+.ant-radio-button-wrapper-disabled {
// }

.toggle-token.ant-radio-group label.ant-checkbox-wrapper:has(input:checked),
.toggle-token.ant-checkbox-group label.ant-checkbox-wrapper:has(input:checked),
.toggle-token.ant-radio-group label.ant-radio-wrapper:has(input:checked),
.toggle-token.ant-checkbox-group label.ant-radio-wrapper:has(input:checked) {
  display: inline-flex !important;
}

.ant-radio-disabled {
  display: none;
}

.ant-radio-button-wrapper:not(:first-child)::before {
  width: 0px;
}

.headerTitle {
  border: 1px solid #efefef;
  min-width: 250px;

  .ant-statistic-title {
    color: unset;
    font-size: 18px;
    font-weight: bold;
  }
}

.badge-success {
  background: #e8f8ee !important;
  color: #32b785 !important;
}

.badge-yellow {
  background: #FFF8E1 !important;
  color: #FF9800 !important;
}

.badge-blue {
  background: #E3F2FD !important;
  color: #2196F3 !important;
}

.bg-success {
  background: #e8f8ee !important;

  .ant-table-cell {
    background: #e8f8ee;
  }

  .ant-table-cell-fix-left {
    background: #e8f8ee;
  }
}

.badge-danger {
  background: #fceae9 !important;
  color: #f2656c !important;
}

.bg-voilet {
  background: #f6eefa;

  .ant-table-cell {
    background: #f6eefa;
  }

  .ant-table-cell-fix-left {
    background: #f6eefa;
  }
}



.@{picker-prefix-cls} {
  &-header {

    &-super-prev-btn,
    &-super-next-btn {
      min-width: auto !important;
    }
  }

  &-cell-in-view {
    &.@{picker-prefix-cls}-cell-today {
      .@{picker-prefix-cls}-cell-inner {
        color: @primary-color;

        &::before {
          border: none;
        }
      }
    }
  }
}

.ant-modal-wrap {
  .ant-modal-confirm {
    &-btns {
      display: flex;
      flex-direction: row-reverse;
      justify-content: center;
      gap: 0.5rem;
    }
  }

  &--reverse {
    .ant-modal-confirm {
      &-btns {
        flex-direction: row;
      }
    }
  }
}

// .ant-timeline {
//   .ant-timeline-label .ant-timeline-item-label {
//     position: absolute;
//     top: -7.001px;
//     width: calc(50% - 12px);
//     text-align: right
//   }
// }

.ant-timeline.ant-timeline-alternate {
  .ant-timeline-item-label {
    position: absolute;
    top: -7px;
    width: calc(50% - 12px);
    text-align: right;
  }

  &-item-right {
    .ant-timeline-item-label {
      left: calc(50% + 14px);
      text-align: left;
    }
  }
}

.table {
  &--alternate {
    tbody {
      &>tr:nth-child(odd) {
        background: @color-lightgray-2;
      }

      &>tr:nth-child(even) {
        background: #F4E9FBA6;
      }
    }
  }

  &--plain {
    .ant-table {
      background-color: transparent !important;
      background-color: transparent !important;
      margin: auto -16px;
      padding: 0 8px;

      .ant-table-content {
        &>table {

          &>thead {
            background-color: transparent !important;

            &>tr {
              &>th {
                background-color: transparent !important;
                font-weight: normal;
                padding: 5px 8px;
              }
            }
          }

          &>tbody {
            &>tr {
              &>td {
                padding: 16px 8px;
              }
            }
          }

        }
      }
    }
  }

  &--fullwidth {
    display: grid;
    max-width: none;
    padding: 1rem 2em;

    .ant-table-content {
      overflow: visible !important;
    }
  }
}

.@{table-prefix-cls} {
  &-thead {
    >tr {
      >th {
        min-width: max-content;
        word-wrap: normal;
      }
    }
  }

  &-column-title {
    word-wrap: normal;
    min-width: max-content;
  }
}

.loading {
  opacity: 0.6;
  position: relative;

  &::before {
    content: "";
    width: 48px;
    height: 48px;
    border: 5px solid black;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50%;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}

input {
  width: 100%;
  padding: 10px;
  font-size: 1em;
  border: 2px solid #ddd;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease;
}

input:invalid {
  border-color: #e74c3c;
}


.cancelButton {
  &:hover {
      background-color: red;
      border-color: red;
      color: white; // To make the text color visible on red background
  }
}
// input:valid {
//   border-color: #2ecc71;
// }

input:focus {
  border-color: #3498db;
}

.nav-text {

  &.pending,
  &.transitioning {
    position: relative;

    &::after {
      content: "";
      width: .8rem;
      height: .8rem;
      border: 2px solid transparent;
      border-top-color: @secondary-color;
      border-right-color: @secondary-color;
      /* Blue color */
      border-radius: 50%;
      position: absolute;
      top: 0;
      right: -1.5rem;
      animation: loading-animation 1s linear infinite;
    }
  }

  &.transitioning {
    position: relative;

    &::after {
      border-top-color: @process-tail-color;
      border-right-color: @process-tail-color;
    }
  }
}

@keyframes loading-animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}