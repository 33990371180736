@statuses: new, sent-to-client, first-reminder, second-reminder, third-reminder, final-reminder, sent-to-euler, waiting-for-invoice, waiting-for-purchase-bill, paid, unpaid,approved,manual-payment;
@bg-colors:#2196F3, #4CAF50, #FF9800, #F57C00, #D32F2F, #B71C1C, #673AB7, #FFC107, #FF9800, #2E7D32,#E53935,#4CAF50,#2E7D32;
@text-colors: white, white, white, white, white, white, white, white, white,white,white,white,white,white;


// Project Statuses
@project-statuses: new-request, enquiry-request-sent, quotation-sent, won---confirmation-client-sent, closed-lost, cancelled, canceled, delivered;
@project-bg-colors: #2196F3, #4CAF50, #FFC107, #2E7D32, #D32F2F, #B71C1C,#B71C1C,#2E7D32;
@project-text-colors: white, white, white, white, white, white,white, white;


// Mixin for dynamic styles
.status-styles(@prefix, @index) when (@index > 0) {
    // Get class name, background color, and text color at the current index
    @status: extract(@statuses, @index);
    @bg-color: extract(@bg-colors, @index);
    @text-color: extract(@text-colors, @index);

    &.@{prefix}--@{status} {
        background-color: @bg-color;
        color: @text-color;
    }

    // Recursive call for the next item in the list
    .status-styles(@prefix, @index - 1);
}


// Mixin for Project Statuses
.project-status-styles(@prefix, @index) when (@index > 0) {
    @status: extract(@project-statuses, @index);
    @bg-color: extract(@project-bg-colors, @index);
    @text-color: extract(@project-text-colors, @index);

    &.@{prefix}--@{status} {
        background-color: @bg-color;
        color: @text-color;
    }

    .project-status-styles(@prefix, @index - 1);
}

.status-styles-with-child(@prefix, @child, @index) when (@index > 0) {
    // Get class name, background color, and text color at the current index
    @status: extract(@statuses, @index);
    @bg-color: extract(@bg-colors, @index);
    @text-color: extract(@text-colors, @index);

    &.@{prefix}--@{status} @{child} {
        background-color: @bg-color;
        color: @text-color;
    }

    // Recursive call for the next item in the list
    .status-styles-with-child(@prefix, @child, @index - 1);
}