.mainWrapper {
    border-radius: 15px;
    background: rgba(249, 249, 249, 0.76);
    padding: 15px;
    margin: 15px auto;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    &-Header {
        display: flex;
        justify-content: flex-end;
    }

    .options {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
}