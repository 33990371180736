.wrapper {
    height: 100%;
    overflow: auto;
    /* overflow: hidden; */
    max-height: calc(100vh - 142px);
    box-sizing: border-box;
    width: 100%;
    padding: 2px 0.5rem 0 0;
}

.scrollToEnd {
    text-align: center;
    padding: 1rem;
}